module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/home/runner/work/Watch-Plus/Watch-Plus/Web/node_modules/gatsby-remark-images","id":"9389b399-9c05-5c6b-8cbc-e246a4011d8c","name":"gatsby-remark-images","version":"6.2.0","pluginOptions":{"plugins":[],"maxWidth":400,"linkImagesToOriginal":false,"backgroundColor":"transparent","wrapperStyle":"margin-top:35px; margin-bottom:35px;","showCaptions":false,"markdownCaptions":false,"quality":50,"withWebp":false,"withAvif":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"root":"/home/runner/work/Watch-Plus/Watch-Plus/Web","gatsbyRemarkPlugins":[{"resolve":"/home/runner/work/Watch-Plus/Watch-Plus/Web/node_modules/gatsby-remark-images","id":"913abf2c-e269-51e7-b66d-01873da95d24","name":"gatsby-remark-images","version":"6.2.0","modulePath":"/home/runner/work/Watch-Plus/Watch-Plus/Web/node_modules/gatsby-remark-images/index.js","pluginOptions":{"plugins":[],"maxWidth":400,"linkImagesToOriginal":false,"backgroundColor":"transparent","wrapperStyle":"margin-top:35px; margin-bottom:35px;"},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"extensions":[".mdx"],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"commonmark":false},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":400,"linkImagesToOriginal":false,"backgroundColor":"transparent","wrapperStyle":"margin-top:35px; margin-bottom:35px;"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":400,"linkImagesToOriginal":false,"backgroundColor":"transparent","wrapperStyle":"margin-top:35px; margin-bottom:35px;","showCaptions":false,"markdownCaptions":false,"quality":50,"withWebp":false,"withAvif":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    }]
